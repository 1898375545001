import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Wrapper, Header } from '../components'
import website from '../../config/website'
import SEO from '../components/SEO'

const IndexWrapper = Wrapper.withComponent('main')
const Post = styled.div`
  margin: 2rem 0 0 0;
  h3 {
    font-family: ${props => props.theme.fontFamily};
    margin-bottom: 0;
    font-weight: 600;
  }
  span {
    font-family: ${props => props.theme.fontFamily};
  }
`

const Index = ({ 
  data: { homepage }, 
  pageContext: { locale }, location 
}) => {
  
  const context = { slug: '/', parent: '' };
  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <Header {...context} />
      <IndexWrapper id={website.skipNavId}>
        <div>
          <a href={homepage.data.link.url} target="_blank" title={homepage.data.image.alt}>
            <img src={homepage.data.image.url} alt={homepage.data.image.alt} />
          </a>
        </div>
      </IndexWrapper>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        image {
          alt
          url
        }
        link {
          url
        }
      }
    }
    #posts: allPrismicPost(
    #  filter: { lang: { eq: "fr-fr" } }
    #  sort: { fields: first_publication_date, order: DESC } 
    #) {
    #  edges {
    #    node {
    #      uid
    #      first_publication_date
    #      last_publication_date
    #      data {
    #        title {
    #          text
    #        }
    #        description
    #        date(formatString: "DD/MM/YYYY")
    #        categories {
    #          category {
    #            document {
    #              data {
    #                name
    #              }
    #            }
    #          }
    #        }
    #        body {
    #          ... on PrismicPostBodyText {
    #            slice_type
    #            id
    #            primary {
    #              text {
    #                html
    #                text
    #              }
    #            }
    #          }
    #          ... on PrismicPostBodyImage {
    #            slice_type
    #            id
    #            primary {
    #              image {
    #                localFile {
    #                  childImageSharp {
    #                    fluid(maxWidth: 1200, quality: 100) {
    #                      ...GatsbyImageSharpFluid_withWebp
    #                    }
    #                  }
    #                }
    #              }
    #            }
    #          }
    #        }
    #      }
    #    }
    #  }
    #}
  }
`
